@import './mixins/index.scss';

@font-face {
  font-family: 'Nunito Sans';
  src: local('Nunito Sans'), url(./fonts/NunitoSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Nunito Sans';
  font-weight: 600;
  src: local('Nunito Sans'), url(./fonts/NunitoSans-SemiBold.ttf) format('truetype');
}

html {
  font-size: 100%;
  background: chaser-color('White');
  overflow: auto;
  overflow-y: overlay;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica',
    'Arial', sans-serif;
  color: chaser-color('Dark Neutral 1');
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html body {
  margin-right: 0 !important;
}

button:disabled {
  opacity: 0.5;
}

button,
input[type='submit'],
input[type='reset'] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit;
}

*:focus {
  outline: none;
}

button:focus {
  outline: none !important;
}

::-webkit-input-placeholder {
  font-size: 13px;
  line-height: 1.6em;
  letter-spacing: 0.01em;
  text-indent: 12px;
}

table {
  border-collapse: collapse;
}

table tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}

table tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

hr {
  width: 100%;
  border-top: 1px solid chaser-color('Mid Neutral 3');
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html label {
  margin-bottom: 0;
}

a:link,
a:hover,
a:active,
a:focus {
  text-decoration: none;
}
