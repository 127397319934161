@import 'src/mixins/index.scss';

.row.cantExpand {
  box-shadow: none !important;
  background-color: white !important;
  cursor: default !important;

  & + tr {
    &:global(.enter),
    &:global(.enter-done),
    &:global(.exit) {
      box-shadow: 0 0 0 3px red;
      display: none;
    }
  }

  button,
  a {
    pointer-events: initial;
  }
}

.row {
  height: 82px;
}

.instalmentsTable {
  width: 100%;
  display: grid;
  align-items: center;
  > div:not(:first-child) {
    border-top: 1px solid chaser-color('Mid Neutral 3');
  }
}

.instalmentsRow {
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 17% 15% 21% 14% auto;
  grid-template-rows: 64px;
  word-break: break-word;

  > div:first-child {
    margin-left: 20px;
  }

  > div:last-child {
    margin-right: 20px;
  }
}
