.loadingBarWrapper {
  position: relative;
  height: 0;
}

.loadingBar {
  position: absolute !important;
  top: 0;
  z-index: 1;
}
