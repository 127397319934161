.overview {
  margin-top: 70px;
  margin-bottom: 160px;
}

.controls {
  min-width: 352px;

  > div {
    min-width: 352px;
    max-width: 360px;
    top: 70px;
  }
}
