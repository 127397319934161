.row {
  height: auto;

  td {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

.table {
  margin-bottom: 10px;
  min-width: fit-content !important;
}
