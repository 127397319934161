@import '../../../../../mixins/index.scss';

.body {
  > div {
    width: 500px;
  }
  margin-top: 52px;
  display: flex;
  justify-content: center;
  overflow: auto;
}

.background {
  background-color: #f9fafc !important;
}

.icon {
  cursor: pointer;
  transform: translateY(4px);
  color: chaser-color('Mid Neutral 2');
  &:hover {
    color: chaser-color('Mid Neutral 1');
  }
  margin-bottom: 5px;
}

.copied {
  animation: fade 0.5s linear;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
