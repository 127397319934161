.loadingMessageContainer {
  display: flex;

  &__center {
    position: absolute;
    top: 50%;
    left: 50%;
  }

  &.margins {
    &.small {
      margin-top: 15px;
      margin-bottom: 15px;
    }

    &.none {
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
}

.ellipsesLoader:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: '\2026'; /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

.overlay {
  background: white;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  z-index: 2;
  cursor: default;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -8px;
  margin-top: -8px;
  border-radius: 6px;
}
