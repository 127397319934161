@import 'src/mixins/index';

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translateY(-50px);
  }
}

@keyframes wiggle {
  15% {
    transform: rotate(-1deg);
  }
  80% {
    transform: rotate(1deg);
  }
}

.enter {
  animation: slideIn 250ms ease-out !important;
}

.exit {
  animation: slideOut 250ms ease-in forwards !important;
}

.updated {
  animation: wiggle 150ms ease !important;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 0;
}

.toast {
  position: relative;
  width: auto;
  max-width: 100%;
  min-height: auto;
  margin-bottom: 10px;
  padding: 0;
  border-radius: 6px;
  background-color: chaser-color('Dark Neutral 1');

  svg {
    display: block;
  }

  @media (min-width: 500px) {
    min-width: 350px;
    max-width: 500px;
  }
}

.body {
  padding: 10px 50px 10px 10px;
}

.close {
  position: absolute;
  top: 4px;
  right: 14px;
  opacity: 0.4;
  color: white;

  .toast:hover & {
    opacity: 0.8;
    color: white;
  }
}
