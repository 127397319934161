.body {
  > div {
    width: 500px;
  }
  margin-top: 52px;
  display: flex;
  justify-content: center;
  overflow: auto;
}

.background {
  background-color: #f9fafc !important;
}
