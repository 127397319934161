.logo {
  display: flex;
  height: 40px;
  justify-content: flex-end;

  img {
    object-fit: contain;
    max-height: 100%;
    max-width: 100%;
  }
}
