@import '../../mixins/index';

.navbar {
  background: chaser-color('Dark Neutral 1');
  height: 40px;
  white-space: nowrap;
  position: fixed;
  width: 100vw;
  top: 0;
  z-index: 1;

  > div,
  > div > div {
    height: 100%;
  }
}
