@mixin highlightOff {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

$chaser-colors: (
  'Orange': #ff780d,
  'Blue': #1b82be,
  'Mid Blue': #529eca,
  'Neutral Blue': #597089,
  'Light Blue': #dde9f7,
  'Light Blue 2': #f0f6fa,
  'Dark Blue': #1573aa,
  'Dark Neutral 1': #3c4757,
  'Mid Neutral 1': #6e767f,
  'Mid Neutral 2': #9ba1ae,
  'Mid Neutral 3': #d7dbe2,
  'Light Neutral 1': #e3e5ea,
  'Light Neutral 2': #f4f5f7,
  'Light Neutral 3': #f9f9fa,
  'Yellow': #fab666,
  'Dark Yellow': #e58d27,
  'Light Yellow': #fff8f0,
  'Red': #eb5757,
  'Light Red': #fef7f7,
  'Green': #2ea970,
  'Dark Green': #129558,
  'White': white,
  'Orange Gradient': linear-gradient(269.23deg, #f47612 40%, #f5640d 80%),
  'Carat': #cfd4dc,
);

@function chaser-color($key: 'Orange') {
  @return if(
    map-has-key($chaser-colors, $key),
    map-get($chaser-colors, $key),
    map-get($chaser-colors, 'Orange')
  );
}
